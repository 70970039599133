<template>
  <div class="goodPower">
    <img :src="data.url+'goodPower/bg_1.png'" alt="" class="bg1">
    <img :src="data.url+'goodPower/bg_2.png?11'"  alt="" class="bg2">
    <div class="content">
      <div class="title">践行善的力量<br/>共创可持续的美好未来</div>
      <div class="total">
        <img :src="data.url+'goodPower/total_1.png'" alt="">
        <img :src="data.url+'goodPower/total_2.png'" alt="">
        <img :src="data.url+'goodPower/total_3.png'" alt="">
      </div>
      <div class="numTotal">
        <div class="totalItem">
          <div class="text">保护</div>
          <div class="num">140万</div>
          <div class="text">英亩</div>
          <div class="text">海洋栖息地</div>
        </div>
        <div class="line"></div>
        <div class="totalItem">
          <div class="text">捐赠</div>
          <div class="num">25万件</div>
          <div class="text">产品</div>
          <div class="text">帮助东欧难民</div>
        </div>
        <div class="line"></div>
        <div class="totalItem">
          <div class="text">捐赠</div>
          <div class="num">150万美元</div>
          <div class="text">支持第一</div>
          <div class="text">儿童医院建设</div>
        </div>
        <div class="line"></div>
        <div class="totalItem">
          <div class="text">节约</div>
          <div class="num">45万吨</div>
          <div class="text">纸张和塑料</div>
          <div class="text">推动绿色发展</div>
        </div>
      </div>
      <div class="mainText">
        生活行善意，美好遇如新
      </div>
      <div class="totalText">*数据统计截至2023年12月底</div>
      <div class="mortIcon">
        <img :src="data.url+'more.png'" alt="">
      </div>
      <div class="downLoadBtn" @click="openUrl">
        点击了解善的力量，让世界更美好
        <img :src="data.url+'right.png'" alt="">
      </div>
      <div class="title" style="margin: 0.28rem 0 0.2rem;">
        绿动如新，践行低碳环保生活
      </div>
      <div class="tab">
        <div 
        v-if="data.lastTab" 
        class="tabItem lastItem" 
        @click="handleTab(true)">
          {{ data.lastTab.text }}
        </div>
        <div class="arrow" style="margin-left: 0.3rem;">
          <img :src="data.url+'goodPower/arrow-left.png'" alt="">
        </div>
        <div class="currentTab" v-if="data.currentTab">
          <span>{{ data.currentTab.text }}</span>
          <div class="line"></div>
          <img class="selectBg" :src="data.url+'goodPower/selectBg.png'" alt="">
        </div>
        <div class="arrow" style="margin-right: 0.3rem;">
          <img :src="data.url+'goodPower/arrow-right.png'" alt="">
        </div>
        <div 
          v-if="data.nextTab"
          class="tabItem nextItem" 
          @click="handleTab(false)">
          {{ data.nextTab.text }}
        </div>
      </div>
      <div 
        v-if="data.currentTab"
        class="imgWrap" 
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd">
        <img :src="data.currentTab.value" alt="">
      </div>
      <div class="moreBtn" @click="goMore">
        点击了解更多
      </div>
      <div class="report">
        <div class="reportWrap">
          <div class="titleImg">
            <img :src="data.url+'goodPower/title.png'" alt="">
          </div>
          <div class="title-small">如新（中国）2022-2023</div>
          <div class="title-big">环境、社会及治理报告</div>
          <div class="lineBottom"></div>
          <div 
            class="moreBtn"
            @click="openReport(1)"
            style="margin: 0.28rem auto 0.26rem;width: 1.32rem;">
            点击了解并下载
          </div>
        </div>
      </div>
      <div class="report">
        <div class="reportWrap">
          <div class="titleImg">
            <img :src="data.url+'goodPower/title.png'" alt="">
          </div>
          <div class="title-small">如新集团2023年度</div>
          <div class="title-big">社会影响力和可持续发展报告</div>
          <div class="lineBottom" style="width: 3rem;"></div>
          <div 
            class="moreBtn"
            @click="openReport(2)"
            style="margin: 0.28rem auto 0.26rem;width: 1.32rem;">
            点击了解并下载
          </div>
        </div>
      </div>
    </div>
    <div class="backBtn" @click="backIndex">
      <img :src="data.url+'goodPower/backBtn.png'" alt="">
    </div>
  </div>
</template>
<script setup>
  import { useRouter } from "vue-router";
  import {reactive,onMounted} from 'vue';
  import {baiduTotal} from "../js/common.js";
  let data=reactive({
    url:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/',//图片地址
    tabList:[
      {
        text:'蓝珍珠环保装',
        value:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/goodPower/lzz.png',
        url:'https://mp.weixin.qq.com/s/4YcIW4qHLmuQMnsUaheS4Q'
      },
      {
        text:'epoch的绿色基因',
        value:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/goodPower/jy.png',
        url:'https://mp.weixin.qq.com/s/rWyslPSt6u19RtyBgxdcrg'
      },
      {
        text:'如新首款0碳产品',
        value:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/goodPower/lt.png',
        url:'https://mp.weixin.qq.com/s/3SC_ezsYpXoJJeaWkf2M8g'
      },
      {
        text:'芸萃善秀环保成绩单',
        value:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/goodPower/hb.png',
        url:'https://mp.weixin.qq.com/s/Sz5Ues5rvhLaxYxeFn7XXg'
      }
    ],
    index:0,//当前下标
    currentTab:null,//当前选择
    lastTab:null,//上一个tab
    nextTab:null,//下一个tab
    startX: 0,
    endX: 0,
  })
  const router = useRouter();
  //触摸开始
  const handleTouchStart=(event)=> {
    data.startX = event.touches[0].clientX;
  }
  //触摸过程中
  const handleTouchMove = (event)=>{
    data.endX = event.touches[0].clientX;
  }
  //触摸结束
  const handleTouchEnd = ()=>{
    const deltaX = data.endX - data.startX;
    handleTab(deltaX>0);
  }
  //操作变化
  const handleTab=(isLeft)=>{
    if (isLeft) {
      data.index-=1;
      if(data.index<0){
        data.index=data.tabList.length-1;
      }
    } else {
      data.index+=1;
      if(data.index>=data.tabList.length){
        data.index=0;
      }
    }
    changeTab();
  }
  //查看更多
  const goMore=()=>{
    window.open(data.currentTab.url);
  }
  //返回首页
  let backIndex=()=>{
    router.replace('/index');
  }
  //打卡善的力量官网
  let openUrl=()=>{
    window.location.href="https://china.nuskin.com/html/content/cssy.html";
  }
  //打卡善的力量官网
  let openReport=(type)=>{
    let url="https://nudrive.cn.nuskin.com/file/rqxdungr4xqftrfbbmps8kcrnoakltqa#";
    if(type==2){
      url="https://nudrive.cn.nuskin.com/file/6ku22ylempg1mq50khivpt1loehxue0s#";
    }
    window.location.href=url;
  }
  //切换tab
  const changeTab=()=>{
    data.currentTab=data.tabList[data.index];
    let last = data.index-1;
    if(last<0){
      last=data.tabList.length-1;
    }
    let next =data.index+1;
    if(next>=data.tabList.length){
      next=0;
    }
    data.lastTab=data.tabList[last];
    data.nextTab =data.tabList[next];
  }
  onMounted(()=>{
    baiduTotal();
    changeTab();
  })
</script>
<style lang="scss" scoped>
.goodPower{
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  overflow-y: auto;
  background: #2F8AA8;
  .content{
    position: relative;
    width: 100%;
    z-index: 1;
    padding-top: 1.3rem;
    padding-bottom: 0.3rem;
    .title{
      color: #FFF;
      text-align: center;
      font-family: Source Han Serif SC;
      font-size: 0.26rem;
      font-style: normal;
      font-weight: 600;
      line-height: 0.4rem; /* 153.846% */
    }
    .total{
      width: 100%;
      padding: 0 0.1rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.2rem;
      img{
        width: 1.12rem;
        height: 1.12rem;
      }
    }
    .numTotal{
      width: 100%;
      padding: 0.1rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.2rem;
      .totalItem{
        .num{
          color: #FFF;
          text-align: center;
          font-family: PingFang SC;
          font-size: 0.18rem;
          font-weight: 600;
          margin: 0.04rem 0;
        }
        .text{
          color: rgba(255, 255, 255, 0.9);
          text-align: center;
          font-family: PingFang SC;
          font-size: 0.11rem;
          font-style: normal;
          font-weight: 400;
          line-height: 0.16rem;
        }
      }
      .line{
        width: 1px;
        height: 0.81rem;
        background: #fff;
      }
    }
    .mainText{
      margin-top: 0.16rem;
      color: #FFF;
      text-align: center;
      font-family: PingFang SC;
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 0.32rem; /* 200% */
      letter-spacing: 1.28px;
    }
    .totalText{
      margin-top: 0.2rem;
      color: #FFF;
      text-align: center;
      font-family: PingFang SC;
      font-size: 0.12rem;
      font-weight: 400;
      letter-spacing: 0.77px;
    }
    .mortIcon{
      position: relative;
      margin: 0.2rem 0;
      text-align: center;
      font-size: 0;
      img{
        width: 0.18rem;
      }
    }
    .downLoadBtn{
      margin: 0.28rem auto 0;
      position: relative;
      width: 2.6rem;
      height: 0.4rem;
      margin-top: 0.16rem;
      border: 1px solid rgba(255, 255, 255, 0.50);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFF;
      font-family: PingFang SC;
      font-size:0.12rem;
      font-style: normal;
      font-weight: 400;
      border-radius: 0.2rem;
      img{
        height: 0.16rem;
        margin-left: 4px;
      }
    }
    .tab{
      width: 100%;
      height: 0.72rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      .arrow{
        width: 0.12rem;
        font-size: 0;
        img{
          width: 100%;
        }
      }
      .tabItem{
        width: 0.66rem;
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 0.13rem;
        color: #FFFFFF;
        line-height: 0.18rem;
      }
      .currentTab{
        flex: 1;
        width: 0;
        height: 100%;
        font-weight: 600;
        font-size: 0.16rem;
        color: #FFFFFF;
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        span{
          position: relative;
          z-index: 1;
        }
        .selectBg{
          position: absolute;
          width: 1.7rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
        .line{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0.05rem;
          width: 1.28rem;
          height: 2px;
          background: linear-gradient(0deg,rgba(255,255,255,0),rgba(255,255,255,1));
        }
      }
      .lastItem{
        position: relative;
        &::before{
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          width: 100%;
          height: 100%;
          background: linear-gradient(270deg,rgba(47,138,168,0),rgba(47,138,168,1));
        }
      }
      .nextItem{
        position: relative;
        &::before{
          position: absolute;
          top: 0;
          right: 0;
          content: '';
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg,rgba(47,138,168,0),rgba(47,138,168,1));
        }
      }
    }
    .imgWrap{
      width: 100%;
      font-size: 0;
      margin-top: 0.2rem;
      img{
        width: 100%;
      }
    }
    .moreBtn{
      margin: 0.2rem auto 0.4rem;
      width: 1.2rem;
      height: 0.4rem;
      border-radius: 0.2rem;
      border: 1px solid rgba(255,255,255,0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 0.12rem;
      color: #FFFFFF;
    }
    .report{
      width: 100%;
      padding:0 0.2rem;
      box-sizing: border-box;
      margin-bottom: 0.4rem;
      .reportWrap{
        border:1px solid rgba($color: #fff, $alpha: 0.5);
        position: relative;
        text-align: center;
        padding-top: 0.4rem;
        .titleImg{
          position: absolute;
          top: -0.34rem;
          left: 0.5rem;
          height:0.66rem;
          font-size: 0;
          background: #2F8AA8;
          img{
            height: 100%;
          }
        }
        .title-small{
          font-weight: 400;
          font-size: 0.12rem;
          color: #FFFFFF;
          line-height: 0.2rem;
        }
        .title-big{
          font-weight: 600;
          font-size: 0.22rem;
          color: #FFFFFF;
          line-height: 0.36rem;
          margin-top: 0.08rem;
        }
        .lineBottom{
          width: 2.33rem;
          height: 0.1rem;
          background: #00C4CD;
          margin:-7px auto 0;
        }
      }
     
    }
  }
  .bg1{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .bg2{
    width: 100%;
    height: 5.4rem;
    position: absolute;
    top: 2.5rem;
    left: 0;
    object-fit: cover;
  }
  .backBtn{
    position: fixed;
    right: 0;
    bottom: 0;
    width: 1.17rem;
    height: 1.28rem;
    font-size: 0;
    z-index: 10;
    img{
      width: 100%;
    }
  }
}
</style>